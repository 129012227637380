import React, { useEffect, useState } from 'react'
import alertify from 'alertifyjs';
import Left from './Left';

let stockimg = localStorage.getItem('stockimg');
function opComment(id) {
    var cArea = document.getElementById('comment' + id);
    if (cArea.style.display == "block") {
        cArea.style.display = "none";
    } else {
        cArea.style.display = "block";
    }
}
function Explore({langData}) {
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let localDocs = localStorage.getItem('localDocs');
    const [suggestData, setSuggestData] = useState([]);
    const [getPosts, setGetPosts] = useState([]);
    const fetchSuggestData = () => {
        fetch(localDocs+'/rn-adaptor/l-suggestions?id=' + userid)
            .then(responseSGG => responseSGG.json())
            .then(suggestData => setSuggestData(suggestData))
            .catch(errorSGG => console.error('Fetch error : ', errorSGG));
    }
    const fetchGetPosts = () => {
        fetch(localDocs+'/rn-adaptor/l-mainpage?&userid=' + userid + '&type=for_you&row=999')
            .then(responseGPo => responseGPo.json())
            .then(getPosts => setGetPosts(getPosts))
            .catch(errorGPo => console.error('Fetch error : ', errorGPo));
    }
    useEffect(() => {
        fetchSuggestData();
        fetchGetPosts();
    }, []);

    const postLike = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-postLike.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    const sendTip = async (userid, dil) => {
        const tipArea = document.getElementById('tipAmount').value;
        const tipComment = document.getElementById('tipComment').value;
        const tipUser = document.getElementById('mtAreaUser').innerHTML;
        const tipPost = document.getElementById('mtAreaPost').innerHTML;
        const data = { userid: userid, dil: dil, tip: tipArea, comment: tipComment, ouser: tipUser, postid: tipPost };
        //console.log(data);
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-sendTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
            document.getElementById('modalTip').style.display = "none";
            document.getElementById('tipAmount').value = "";
            document.getElementById('tipComment').value = "";
            if (plJson.status == 0) { alertify.error(plJson.message, 2); }
            if (plJson.status == 1) { alertify.success(plJson.message, 2); }

        } catch (error) { console.log("An error :", error); }
    };
    const getTip = async (userid, postid) => {
        const data = { userid: userid };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-getTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const gtJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", gtJson);
            document.getElementById('modalTip').style.display = "flex";
            document.getElementById('mtAreaImg').src = gtJson.pp;
            document.getElementById('mtAreaP').innerHTML = gtJson.name + ' ' + gtJson.surname;
            document.getElementById('mtAreaA').href = '/user-detail' + gtJson.userid;
            document.getElementById('mtAreaA').innerHTML = '@' + gtJson.username;
            document.getElementById('mtAreaUser').innerHTML = userid;
            document.getElementById('mtAreaPost').innerHTML = postid;
        } catch (error) { console.log("An error :", error); }
    };
    const postBook = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-addBookmarkt.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const pbJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", pbJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    let lastArea = "eaUsers";
    function opArea(area){
        console.log(area);
        if(area == "eaUsers"){document.getElementById('searchBtn').style.display="block";}else{document.getElementById('searchBtn').style.display="none";}
        document.getElementById(lastArea+"Tab").classList.remove('eahFilterActive');
        document.getElementById(''+lastArea).style.display = "none";
        document.getElementById(area+"Tab").classList.add('eahFilterActive');
        document.getElementById(''+area).style.display = "block";
        lastArea = area;
    }
    //console.log(getPosts);
    
    function opMenu(type){
        if(type == "open"){document.getElementById('searchMenu').style.right = "-20px";}else{document.getElementById('searchMenu').style.right = "-300px";}
    }
    const searchFunc = async (userid, dil) => {
        let username = document.getElementById('username').value;
        let rating = document.getElementById('rating').value;
        let sexual = document.getElementById('sexual').value;
        let minAge = document.getElementById('minAge').value;
        let maxAge = document.getElementById('maxAge').value;
        let eye = document.getElementById('eye').value;
        let hair = document.getElementById('hair').value;
        let minShoe = document.getElementById('minShoe').value;
        let maxShoe = document.getElementById('maxShoe').value;
        let height = document.getElementById('height').value;
        let ethnicity = document.getElementById('ethnicity').value;
        const data = { userid: userid, dil: dil, username : username, rating : rating, sexual : sexual, minAge : minAge, maxAge : maxAge, eye : eye, hair : hair, minShoe : minShoe, maxShoe : maxShoe, height : height, ethnicity : ethnicity};
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-search.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const searchJson = await response.json();
            console.log("PHP tarafından dönen yanıt:", searchJson);
            setSuggestData(searchJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    return (
        <div>
            
            <div id="modalTip" className="modalTip">
                <a onClick={() => document.getElementById('modalTip').style.display = "none"} className='mmClose'></a>
                <div className='mtArea'>
                    <h5>{langData.send_tip}</h5>
                    <span onClick={() => document.getElementById('modalTip').style.display = "none"}>x</span>
                    <div className='mtUserArea'>
                        <img id="mtAreaImg" src={stockimg} />
                        <p id="mtAreaP">Name Surname</p>
                        <a href={'/user-detail/1'} id='mtAreaA'>@username</a>
                    </div>
                    <p id='mtAreaUser'>0</p>
                    <p id='mtAreaPost'>0</p>
                    <div className='mtAmountArea'>
                        <p>{langData.amount}</p>
                        <img src={require('../assets/icons/tip.webp')} />
                        <input id="tipAmount" placeholder={langData.amount} />
                    </div>
                    <div className='mtDescArea'>
                        <p>{langData.message}</p>
                        <textarea id='tipComment'></textarea>
                    </div>
                    <div className='mtButtonArea'>
                        <a onClick={() => document.getElementById('modalTip').style.display = "none"}>{langData.cancel}</a>
                        <a onClick={() => sendTip(userid, dil)}>{langData.send_tip}</a>
                    </div>
                </div>
            </div>
            
            <div className='raFilters' id='searchMenu'>
                <a className='rafClose' onClick={()=>opMenu('close')}>x</a>
                <div className='raFilter'>
                    <h6>{langData.username}</h6>
                    <input id='username' placeholder='Type here..' />
                </div>
                <div className='raFilter'>
                    <h6>{langData.rating}</h6>
                    <select id='rating'>
                        <option value='all'>{langData.all}</option>
                        <option value='sfw'>SFW</option>
                        <option value='nsfw'>NSFW</option>
                    </select>
                </div>
                <div className='raFilter'>
                    <h6>{langData.sexual_orientation}</h6>
                    <select id='sexual'>
                        <option value='all'>{langData.all}</option>
                        <option value='heterosexual_straight'>Heterosexual/Straight</option>
                        <option value='homosexual'>Homosexual</option>
                        <option value='bisexual'>Bisexual</option>
                        <option value='bisexual'>Bisexual</option>
                        <option value='heteroflexible'>Heteroflexible</option>
                        <option value='polysexual'>Polysexual</option>
                        <option value='queer'>Queer</option>
                        <option value='androgynosexual'>Androgynosexual</option>
                        <option value='androsexual'>Androsexual</option>
                        <option value='gray_a'>Gray-A</option>
                        <option value='gynosexual'>Gynosexual</option>
                        <option value='objectumsexual'>Objectumsexual</option>
                        <option value='pansexual_omnisexual'>Pansexual/Omnisexual</option>
                        <option value='skolisexual'>Skolisexual</option>
                    </select>
                </div>
                <div className='raFilter'>
                    <h6>{langData.age}</h6>
                    <input className='rafiDouble' type='number' id='minAge' placeholder='min' />
                    <input className='rafiDouble' type='number' id='maxAge' placeholder='max' />
                </div>
                <div className='raFilter'>
                    <h6>{langData.eye_color}</h6>
                    <select id='eye'>
                        <option value='all'>{langData.all}</option>
                        <option value='amber'>Amber</option>
                        <option value='blue'>Blue</option>
                        <option value='brown'>Brown</option>
                        <option value='green'>Green</option>
                        <option value='grey'>Grey</option>
                        <option value='hazel'>Hazel</option>
                        <option value='red'>Red</option>
                        <option value='violet'>Violet</option>
                        <option value='mixed'>Mixed</option>
                    </select>
                </div>
                <div className='raFilter'>
                    <h6>{langData.hair_color}</h6>
                    <select id='hair'>
                        <option value='all'>{langData.all}</option>
                        <option value='black'>Black</option>
                        <option value='blonde'>Blonde</option>
                        <option value='dark_blonde'>Dark Blonde</option>
                        <option value='platinum_blonde'>Platinum Blonde</option>
                        <option value='brunette'>Brunette</option>
                        <option value='dark_brown'>Dark Brown</option>
                        <option value='red_head'>Red Head</option>
                        <option value='auburn'>Auburn</option>
                        <option value='blue'>Blue</option>
                        <option value='grey'>Grey</option>
                        <option value='pin'>Pink</option>
                        <option value='purple'>Purple</option>
                        <option value='orange'>Orange</option>
                        <option value='many'>Many</option>
                    </select>
                </div>
                <div className='raFilter'>
                    <h6>{langData.shoe_size}</h6>
                    <input className='rafiDouble' type='text' id='minShoe' placeholder='min' />
                    <input className='rafiDouble' type='text' id='maxShoe' placeholder='max'/>
                </div>
                <div className='raFilter'>
                    <h6>{langData.height}</h6>
                    <select id='height'>
                        <option value='all'>{langData.all}</option>
                        <option value='little'>Little</option>
                        <option value='short'>Short</option>
                        <option value='average'>Average</option>
                        <option value='tall'>Tall</option>
                        <option value='very_tall'>Very Tall</option>
                    </select>
                </div>
                <div className='raFilter'>
                    <h6>{langData.ethnicity}</h6>
                    <select id='ethnicity'>
                        <option value='all'>{langData.all}</option>
                        <option value='white_caucasian'>White/Caucasian</option>
                        <option value='latino_hispanic'>Latino/Hispanic</option>
                        <option value='asian'>Asian</option>
                        <option value='black_ebony'>Black/Ebony</option>
                        <option value='mixed'>Mixed</option>
                        <option value='native_american_alaskan'>Native American/Alaskan</option>
                        <option value='pacific_islander'>Pacific Islander</option>
                        <option value='middle_eastern'>Middle Eastern</option>
                        <option value='east_indian'>East Indian</option>
                        <option value='other'>Other</option>
                    </select>
                </div>
                <a onClick={()=>searchFunc(userid, dil)} className='rafBtn'>{langData.search}</a>
            </div>
            <div className='leftAreaC'>
                <Left title="Title" /> 
            </div>
            <div className='fullAreaC'>
                <div className='exploreArea'>
                    <div className='eaHeader'>
                        <a onClick={()=>window.history.back()} className='leftArrow'><img src={require('../assets/icons/arrow.webp')} /></a>
                        <h3>{langData.explore}</h3>
                        <div className='eahFilter'>
                            <a id="eaUsersTab" className="eahFilterActive" onClick={()=>opArea('eaUsers')}><img src={require('../assets/icons/creators.webp')} />{langData.creators}</a>
                            <a id="eaPostsTab" onClick={()=>opArea('eaPosts')}><img src={require('../assets/icons/posts.webp')} />{langData.posts}</a>
                            <a id="eaVideosTab" onClick={()=>opArea('eaVideos')}><img src={require('../assets/icons/video.webp')} />{langData.video_store}</a>
                            {/*<a><img src={require('../assets/icons/hashtags.webp')} />Hashtags</a>
                            <a><img src={require('../assets/icons/live.webp')} />Live</a>*/}
                        </div>
                        <div className='eahSearch'>
                            <a id="searchBtn" onClick={()=>opMenu('open')}><img src={require('../assets/icons/search.webp')} />{langData.search}</a>
                        </div>
                    </div>
                    <div className='eaBody'>
                        <div id="eaUsers">
                            {suggestData.map(user =>
                                <span onClick={()=>window.location.href='/user-detail/'+user.id} key={user.id} className='eaUser'>
                                    <img src={user.pp} />
                                    <div className='eaUserDatas'>
                                        <p>{user.name} {user.surname}</p>
                                        <a href={'/user-detail/' + user.id} className='eaUserLink'>@{user.username}</a>
                                        <div className='eaUserData'>
                                            <a><img src={require('../assets/icons/posts.webp')} />{user.timg}</a>
                                            <a><img src={require('../assets/icons/video.webp')} />{user.tvid}</a>
                                        </div>
                                    </div>
                                </span>
                            )}
                        </div>
                        <div id="eaPosts">
                            {getPosts ?(
                                <div>
                                {getPosts.map(post =>
                                    <div key={post.id}>
                                        {post.image ? (
                                            <div key={post.id} className='eaPost'>
                                                <img src={post.image} />
                                                <div className='eaPostDatas'>
                                                    <div className='eaPostUser'>
                                                        <img src={post.pp} />
                                                        <p>{post.name} {post.surname}</p>
                                                        <a href={'/user-detail/' + post.userid} className='eaUserLink'>@{post.username}</a>
                                                        <span><img onClick={()=>window.location.href = '/post/'+post.id } src={require('../assets/icons/threedots.webp')} /></span>
                                                    </div>
                                                    <div className='eaPostFunctions'>
                                                        {post.ulike > 0 ? (
                                                            <a onClick={() => postLike(userid, post.id, dil)} className='eaPostFunction'><img src={require('../assets/icons/heartdoll.webp')} /><p>{post.like}</p></a>
                                                        ) : (
                                                            <a onClick={() => postLike(userid, post.id, dil)} className='eaPostFunction'><img src={require('../assets/icons/heart.webp')} /><p>{post.like}</p></a>
                                                        )}
                                                        <a href={'/post/'+post.id} className='eaPostFunction'><img src={require('../assets/icons/comment.webp')} /></a>
                                                        <a onClick={() => { getTip(post.userid, post.id, post.pp, post.username, post.name, post.surname) }} className='eaPostFunction eaPostTip'><img src={require('../assets/icons/tip.webp')} /> <p>{langData.send_tip}</p></a>
                                                        {post.ubook > 0 ? (
                                                            <a onClick={() => postBook(userid, post.id, dil)} className='eaPostFunction eaPostBook'><img src={require('../assets/icons/bookmarkdoll.webp')} /></a>
                                                        ) : (
                                                            <a onClick={() => postBook(userid, post.id, dil)} className='eaPostFunction eaPostBook'><img src={require('../assets/icons/bookmark.webp')} /></a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ('')}
                                    </div>
                                )}
                            </div>
                            ):('')}
                        </div>
                        <div id="eaVideos">
                            {getPosts ?(
                                <div>
                                {getPosts.map(post =>
                                    <div key={post.id}>
                                        {post.video ? (
                                            <div key={post.id} className='eaPost'>
                                                <video width="100%;" height="400px" controls><source src={post.video} type="video/mp4" /></video>
                                                <div className='eaPostDatas'>
                                                    <div className='eaPostUser'>
                                                        <img src={post.pp} />
                                                        <p>{post.name} {post.surname}</p>
                                                        <a href={'/user-detail/' + post.id} className='eaUserLink'>@{post.username}</a>
                                                        <span><img onClick={()=>window.location.href = '/post/'+post.id } src={require('../assets/icons/threedots.webp')} /></span>
                                                    </div>
                                                    <div className='eaPostFunctions'>
                                                        {post.ulike > 0 ? (
                                                            <a onClick={() => postLike(userid, post.id, dil)} className='eaPostFunction'><img src={require('../assets/icons/heartdoll.webp')} /><p>{post.like}</p></a>
                                                        ) : (
                                                            <a onClick={() => postLike(userid, post.id, dil)} className='eaPostFunction'><img src={require('../assets/icons/heart.webp')} /><p>{post.like}</p></a>
                                                        )}
                                                        <a href={'/post/'+post.id} className='eaPostFunction'><img src={require('../assets/icons/comment.webp')} /></a>
                                                        <a onClick={() => { document.getElementById('tipArea' + post.id).style.display === "block" ? (document.getElementById('tipArea' + post.id).style.display = "none") : (document.getElementById('tipArea' + post.id).style.display = "block") }
                                                        } className='eaPostFunction eaPostTip'><img src={require('../assets/icons/tip.webp')} /> <p>{langData.send_tip}</p></a>
                                                        {post.ubook > 0 ? (
                                                            <a onClick={() => postBook(userid, post.id, dil)} className='eaPostFunction eaPostBook'><img src={require('../assets/icons/bookmarkdoll.webp')} /></a>
                                                        ) : (
                                                            <a onClick={() => postBook(userid, post.id, dil)} className='eaPostFunction eaPostBook'><img src={require('../assets/icons/bookmark.webp')} /></a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ('')}
                                    </div>
                                )}
                            </div>
                            ):('')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Explore;